<template>
  <div class="wrapper">
    <div class="favorites" @click="favorites">
      <div class="favorites-icon" :class="{active: favoritesActive}"></div>
      <div class="favorites-text" v-show="!favoritesActive">收藏</div>
      <div class="favorites-text" v-show="favoritesActive">已收藏</div>
    </div>
    <div class="share" @click="share">
      <div class="share-icon"></div>
      <div class="share-text">分享</div>
    </div>
    <div class="reading" v-show="!signUpFlag" @click="signUp">我要参加</div>
    <div class="sign-up" v-show="signUpFlag">已报名</div>
  </div>
</template>

<script>
export default {
  name: 'ActivityDetailBottom',
  props: {
    favoritesActive: {
      type: Boolean,
      default: false
    },
    signUpFlag: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    favorites () {
      this.$emit('favorites')
    },
    signUp () {
      this.$emit('signUp')
    },
    share () {
      this.$emit('share')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  position fixed
  bottom 0
  display flex
  align-items center
  width 750px
  height 96px
  padding-bottom 0
  padding-bottom constant(safe-area-inset-bottom)
  padding-bottom env(safe-area-inset-bottom)
  border-top 1px solid rgba(234, 234, 234, 1)
  background #FDFEFD

  .favorites
    display flex
    align-items center
    width 193px
    height 46px
    border-right 2px solid rgba(234, 234, 234, 1)

    .favorites-icon
      width 30px
      height 28px
      margin 0 9px 0 53px
      bg-image('~@/assets/img/book-detail/favorites-icon')
      background-size 100% 100%
      background-repeat no-repeat

      &.active
        bg-image('~@/assets/img/book-detail/favorites-icon-active')

    .favorites-text
      width 90px
      line-height normal
      font-size 26px
      color rgba(153, 153, 153, 1)
      padding 10px 0

  .share
    display flex
    align-items center
    width 186px
    height 46px

    .share-icon
      width 30px
      height 28px
      margin 0 8px 0 49px
      bg-image('~@/assets/img/book-detail/share')
      background-size 100% 100%
      background-repeat no-repeat

    .share-text
      width 90px
      line-height normal
      font-size 26px
      color rgba(153, 153, 153, 1)
      padding 10px 0

  .reading
    width 340px
    height 26px
    padding 17px 0
    line-height 26px
    text-align center
    color rgba(255, 255, 255, 1)
    border-radius 30px
    background rgba(179, 53, 58, 1)

  .sign-up
    width 340px
    height 26px
    padding 17px 0
    line-height 26px
    text-align center
    color rgba(255, 255, 255, 1)
    border-radius 30px
    background rgba(199, 199, 199, 1)
</style>
