<template>
  <div class="wrapper">
    <div class="cover">
      <img class="cover-img" :src="activityInfo.activity_cover" alt=""/>
    </div>
    <div class="title">
      {{ activityInfo.activity_title }}
    </div>
    <div class="info">
      <div class="icon-time"></div>
      <div class="info-content">活动时间：</div>
      <div class="info-val">{{ activityInfo.activity_start_time }}</div>
    </div>
    <div class="info">
      <div class="icon-time"></div>
      <div class="info-content">截止时间：</div>
      <div class="info-val"> {{ activityInfo.activity_end_time }}</div>
    </div>
    <div class="info">
      <div class="icon-addr"></div>
      <div class="info-content">活动地点：</div>
      <div class="info-val">{{ activityInfo.activity_addr }}</div>
    </div>
    <div class="desc">
      <div class="desc-title">
        <div class="title-border-left"></div>
        <div class="title-content">活动详情</div>
        <div class="title-border-right"></div>
      </div>
      <div class="desc-title2">
        <div class="title2-content">活动简介</div>
      </div>
      <div class="content" v-html="activityInfo.activity_desc">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityDetailInfo',
  props: {
    activityInfo: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  display flex
  flex-wrap wrap
  justify-content center
  width 750px
  background rgba(245, 245, 244, 1)

  .cover
    width 690px
    height 276px
    border-radius 10px
    overflow hidden

    .cover-img
      width 100%
      height 100%

  .title
    width 690px
    margin-top 20px
    line-height 36px
    font-size 30px
    color rgba(51, 51, 51, 1)

  .info
    display flex
    align-items center
    width 690px
    margin-top 10px

    .icon-time
      width 19px
      height 19px
      margin-top 10px
      bg-image('~@/assets/img/time')
      background-size 100% 100%
      background-repeat no-repeat

    .icon-addr
      width 19px
      height 19px
      margin-top 10px
      bg-image('~@/assets/img/addr')
      background-size 100% 100%
      background-repeat no-repeat

    .info-content
      line-height normal
      font-size 26px
      color rgba(153, 153, 153, 1)
      margin-left 10px

    .info-val
      width 500px
      line-height normal
      font-size 26px
      color rgba(153, 153, 153, 1)

  .desc
    display table
    width 750px
    padding 30px 0
    margin-top 40px
    background rgba(255, 255, 255, 1)
    border-radius 30px 30px 0 0

    .desc-title
      display flex
      justify-content center
      align-items center
      width 750px

      .title-border-left
        width 98px
        height 2px
        bg-image('~@/assets/img/activity-detail/title')
        background-size 100% 100%
        background-repeat no-repeat

      .title-border-right
        width 98px
        height 2px
        bg-image('~@/assets/img/activity-detail/title')
        background-size 100% 100%
        background-repeat no-repeat
        transform rotate(180deg)

      .title-content
        padding 0 18px
        font-size 30px
        font-weight bold
        color rgba(51, 51, 51, 1)

    .desc-title2
      display flex
      align-items center
      width 720px
      height 30px
      padding-left 22px
      margin-top 60px
      border-left 8px solid rgba(179, 53, 58, 1)

      .title2-content
        line-height normal
        font-size 30px
        color rgba(179, 53, 58, 1)
        font-weight bold

    .content
      width 690px
      font-size 30px
      color rgba(51, 51, 51, 1)
      line-height 48px
      margin 30px auto
.content >>> strong
  font-weight bold

.content >>> strong *
  font-weight bold

.content >>> img
  max-width 100%
  height auto
</style>
