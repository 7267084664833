<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="activity-detail">
      <scroll ref="scroll" class="scroll" :data="data">
        <div>
          <activity-detail-header></activity-detail-header>
          <activity-detail-info :activity-info="activityInfo"></activity-detail-info>
        </div>
      </scroll>
      <activity-detail-bottom
        :favorites-active="favoritesActive"
        @favorites="favorites"
        :sign-up-flag="signUpFlag"
        @signUp="signUp"
        @share="share"
      ></activity-detail-bottom>
      <overlay
        :show="showShare"
      >
        <share
          :show="showShare"
          :pic-url="activityInfo.activity_cover_base64"
          :name="activityInfo.activity_title"
          :author="activityInfo.activity_title"
          :user-name="userName"
          :agency-guid="agencyGuid"
          :user-guid="userGuid"
          :start-time="activityInfo.activity_start_date"
          :end-time="activityInfo.activity_end_date"
          :addr="activityInfo.activity_addr"
          :activity_guid="activityInfo.activity_guid"
          :activity_type_new="activityInfo.activity_type_new"
          @shareClose="showShare=false"
        ></share>
      </overlay>
    </div>
  </transition>
</template>
<script>
import Scroll from '@/components/scroll/scroll'
import ActivityDetailHeader from './components/Header'
import ActivityDetailInfo from './components/Info'
import ActivityDetailBottom from './components/Bottom'
import { getActivitiesDetail } from '@/api/Activity'
import { Toast, Overlay } from 'vant'
import { mapGetters } from 'vuex'
import { getCUserFavoritesFlag, setCUserFavorites, getCUserActivity, cUserActivity } from '@/api/Cuser'
import { appInit } from '@/utils/init'
import Share from './components/Share'

export default {
  name: 'ActivityDetail',
  components: {
    Scroll,
    ActivityDetailHeader,
    ActivityDetailInfo,
    ActivityDetailBottom,
    Overlay,
    Share
  },
  computed: {
    ...mapGetters([
      'userGuid',
      'agencyGuid',
      'userName'
    ])
  },
  mounted () {
    document.title = this.$route.params.name
    this._getActivitiesDetail()
  },
  data () {
    return {
      activityInfo: {},
      favoritesActive: false,
      signUpFlag: false,
      showShare: false,
      data: []
    }
  },
  methods: {
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    },
    async _getActivitiesDetail () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      await getActivitiesDetail({
        activity_guid: this.$route.params.guid
      }).then(res => {
        if (res.code === 200) {
          this.activityInfo = res.data
          Toast.clear()
          this.refresh()
          setTimeout(() => {
            this.refresh()
          }, 2000)
        }
      })
      getCUserFavoritesFlag({
        resources_guid: this.activityInfo.activity_guid,
        c_user_guid: this.userGuid
      }).then(res => {
        if (res.code === 200) {
          this.favoritesActive = res.data
        }
      })
      getCUserActivity({
        activity_guid: this.activityInfo.activity_guid,
        c_user_guid: this.userGuid
      }).then(res => {
        if (res.code === 200) {
          this.signUpFlag = res.data
        }
      })
    },
    favorites () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      setCUserFavorites({
        c_user_guid: this.userGuid,
        favorites_obj: this.activityInfo.activity_guid,
        favorites_type: 4,
        favorites_status: this.favoritesActive ? -1 : 1
      }).then(res => {
        if (res.code === 200) {
          this.favoritesActive = !this.favoritesActive
        }
        Toast.clear()
      })
    },
    signUp () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      cUserActivity({
        activity_guid: this.activityInfo.activity_guid,
        c_user_guid: this.userGuid
      }).then(res => {
        if (res.code === 200) {
          this.signUpFlag = !this.signUpFlag
          Toast.clear()
        } else if (res.code === 401) {
          Toast.clear()
          appInit(this.agencyGuid, this.$route.path)
        } else if (res.code === 402) {
          Toast.clear()
          this.$router.push({
            name: 'Login',
            params: {
              link: this.$route.path
            }
          })
        } else {
          Toast.fail(res.msg)
        }
      })
    },
    share () {
      this.$nextTick(() => {
        this.showShare = true
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.activity-detail
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 97px
    bottom calc(constant(safe-area-inset-bottom) + 97px)
    bottom calc(env(safe-area-inset-bottom) + 97px)
    overflow hidden
</style>
